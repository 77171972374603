<template>
  <div class="order-evaluate" v-loading="loading">
    <div class="header">
      <divider-title text="评价" />
    </div>
    <div class="container">
      <div class="order-info">
        <div>订单编号：{{ orderInfo.orderno }}</div>
        <div>购买时间：{{ orderInfo.ct||0 | dateTimeFormat }}</div>
      </div>
      <!-- <div class="ware-list">
        <div class="ware-item" v-for="(item, index) in wareList" :key="index">
          <img :src="item.product.pic.picUrl" alt />
          <div class="ware-info">
            <span class="warename">购买商品：{{ item.product.title }}</span>
            <span>购买时间：{{ orderInfo.ct | dateTimeFormat }}</span>
          </div>
        </div>
      </div>-->
      <div class="rate-container">
        <div class="rate">
          <span>宝贝与描述相符（打分匿名）</span>
          <el-rate
            v-model="rate"
            class="rate-start"
            :colors="['#aa2128', '#aa2128', '#aa2128']"
            void-color="#cdcdcd"
            void-icon-class="el-icon-star-on"
          ></el-rate>
        </div>
        <div class="textarea-box">
          <textarea placeholder="请对本产品做出评价，不低于20字哦~谢谢！" v-model="reason"></textarea>
        </div>
        <el-upload
          class="upload"
          action="#"
          list-type="picture-card"
          :auto-upload="false"
          :file-list="fileList"
          :on-change="uploadImage"
          :multiple="true"
        >
          <i slot="default" class="el-icon-plus"></i>
          <template slot="file" slot-scope="{ file }">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              @click="handlePictureCardPreview(file)"
              alt
            />
            <!-- <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
            </span>-->
            <span class="remove" @click="handleRemove(file)"></span>
          </template>
        </el-upload>
        <div class="submit" @click="submit">发表评论</div>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import dividerTitle from "@/components/dividerTitle";
import { EVALUATE, UPLOAD, ORDER_DETAIL } from "../../service/order";

export default {
  name: "OrderEvaluate",
  components: { dividerTitle },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      dialogImageUrl: "",
      fileList: [],
      reason: "",
      wareList: [
        // {
        //   wareName: "钻石1",
        //   size: "均码",
        //   image:
        //     "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
        //   addr: "北京市朝阳区XXX小区xx栋1606号",
        //   orderId: 131312123132132,
        //   date: "2020-03-04"
        // }
      ],
      orderInfo: [],
      rate: 0
    };
  },
  computed: {},
  created() {
    this.orderId = this.$route.query.orderId;
    this.getOrderDetail(this.orderId);
  },
  methods: {
    getOrderDetail(orderid) {
      this.loading = true;
      ORDER_DETAIL({ orderid }).then(res => {
        this.loading = false;
        this.orderInfo = res.attachment.order;
        this.wareList = this.orderInfo.products;
        // console.log("this.orderInfo", this.orderInfo.products);
      });
    },
    handleRemove(file) {
      let uid = file.uid;
      this.fileList = this.fileList.filter(item => item.uid !== uid);
    },
    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadImage(file) {
      this.fileList.push(file);
      return false;
    },
    validParams() {
      let message = null;
      if (this.reason.length <= 20) {
        message = "请输入不少于20字的评价！";
      } else if (!this.rate) {
        message = "请进行打分！";
      }
      if (message) {
        this.$message({
          type: "warning",
          message
        });
      }
      return !message;
    },
    submit() {
      if (!this.validParams()) {
        return;
      }
      this.loading = true;
      let formData = new FormData();
      // let uid = this.$store.getters["user/getUid"];
      // formData.append("uid", uid);
      this.fileList.forEach(item => {
        formData.append("files", item.raw);
      });
      console.log(this);
      UPLOAD(formData).then(res => {
        if(res.status == 200){
          this.loading = true;
          this.$router.push({
            path:'/orderlist'
          })
        }else if (res.status !== 200) {
          this.loading = false;
          return;
        }
        var ids = res.attachment.ids || [];
        EVALUATE({
          orderid: this.orderId,
          star: this.rate,
          pids: ids.join(","),
          c: this.reason
        }).then(res => {
          this.loading = false;
          console.log(res);
        });
      });
    }
  }
};
</script>
<style lang="less" scoped>
.order-evaluate {
  background: #f5f5f5;
  box-shadow: 0px 4px 5px 2px #d6d6d6;
  .header {
    padding-top: 28px;
    height: 96px;
    background: #fff;
  }
  .container {
    padding: 20px 0 86px 0;
    .order-info {
      padding: 20px 54px;
      background: #fff;
      flex-direction: column;
      > div {
        font-size: 16px;
        line-height: 2;
      }
    }
    .ware-list {
      padding: 0 54px;
      background: #fff;
      box-shadow: 0px 4px 5px 2px #d6d6d6;

      .ware-item {
        width: 100%;
        height: 240px;
        padding: 20px 0;
        display: flex;
        align-items: center;
        img {
          width: 200px;
          height: 200px;
          border-radius: 16px;
        }
        .ware-info {
          height: 200px;
          margin-left: 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          > span {
            font-size: 17px;
            line-height: 50px;
            color: #000;
          }
        }
      }
    }
    .rate-container {
      background: #fff;
      margin-top: 20px;
      padding: 6px 54px 54px;
      .rate {
        span {
          display: block;
          line-height: 40px;
          font-size: 16px;
        }
        .rate-start {
          height: 30px;
        }
      }
      .textarea-box {
        width: 100%;
        height: 200px;
        margin-top: 15px;
        border: 1px solid #cfcfcf; /*no*/
        padding: 5px 35px;
        textarea {
          border: none;
          outline: none;
          line-height: 30px;
          width: 100%;
          height: 100%;
          resize: none;
          color: #666;
          font-size: 16px;
        }
        // textarea::-webkit-input-placeholder {
        //   color: #999;
        // }
      }
      .upload {
        margin-top: 26px;
        .remove {
          width: 26px;
          height: 26px;
          position: absolute;
          right: -11px;
          top: -9px;
          background-image: url("../../assets/images/remove.png");
          background-size: 100% 100%;
        }
      }
      .submit {
        margin: 100px auto 0;
        width: 200px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        background: #921719;
        font-size: 15px;
        cursor: default;
      }
    }
  }
}
</style>
<style lang="less">
.order-evaluate {
  .rate-start {
    .el-rate__icon {
      font-size: 30px;
    }
  }
  .el-upload-list--picture-card .el-upload-list__item {
    overflow: visible;
    border: none;
    margin: 0 16px 16px 0;
  }
  .el-upload-list--picture-card .el-upload-list__item:focus {
    outline: none;
  }
  .el-upload--picture-card {
    border: 1px solid #cfcfcf; /*no*/
    background: transparent;
    border-radius: 0;
  }
}
</style>
